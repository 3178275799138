import React, { useMemo } from 'react'
import { get } from 'lodash'

import { StaticQuery, graphql, Link } from 'gatsby'

import { Inline, INLINES, MARKS } from '@contentful/rich-text-types'
import { Options } from '@contentful/rich-text-react-renderer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { inlinePDF } from '../utils/articleUtils'

import SEO from '../components/seo'

import '../layout/servicePages.less'
import LayoutNew from 'layout/LayoutNew'

export const formatUrl = (data: any, slug: string) => {
  const allPages = data?.allSitePage?.edges || []
  let url = allPages.find((link: any) => link.node.context && link.node.context.slug == slug)
  return url
}

const Terms = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulWebsiteConfiguration {
          cookieDisclaimer {
            raw
          }
          privacyPolicyBody {
            raw
          }
        }
        allSitePage {
          edges {
            node {
              path
              context {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const options: Options = useMemo(
        () => ({
          renderNode: {
            [INLINES.HYPERLINK]: (node, children) => (
              <a className="Link" target="_blank" href={get(node, 'data.uri')}>
                {children}
              </a>
            ),
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
              let slug = get(node, 'data.target.slug')
              const url = formatUrl(data, slug)
              return (
                <Link className="Link" to={`${url?.node?.path ?? slug}`}>
                  {children}
                </Link>
              )
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) =>
              inlinePDF(INLINES.ASSET_HYPERLINK, node.data.target as Inline, children),
          },
          renderMark: {
            [MARKS.BOLD]: (text) => <p className="u-textWeightBold u-inline">{text}</p>,
          },
        }),
        [data],
      )

      const privacyPolicyPageData = data.contentfulWebsiteConfiguration

      return (
        <LayoutNew>
          <SEO description="" title="Terms" />

          <article className="Legals">
            <div
              className="Page-container Legals--header"
              style={{
                paddingBottom: '2rem',
              }}
            >
              <div className="Legals-container">
                <h1 className="Heading Heading--h1">Personuppgiftspolicy</h1>
              </div>
            </div>
            <div className="Legals--body">
              <div className="Page-container">
                <div className="Legals-container">
                  {renderRichText(privacyPolicyPageData.privacyPolicyBody, options)}
                </div>
              </div>
            </div>
          </article>
        </LayoutNew>
      )
    }}
  />
)

export default Terms
